



















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiSetMealDetail, apiSetMealAdd, apiSetMealEdit, apiMealModule } from '@/api/shop'
import { throttle } from '@/utils/util'
import config from '@/config'
@Component
export default class SetMealEdit extends Vue {
  /** S Data **/

  identity: number | null = null; // 当前编辑用户的身份ID

  // 添加商城表单数据
  form: any = {
    name: '', // 名称
    explain: '',
    status: 1,
    sort: '',
    set_meal_price: [{
      time: '',
      time_type: 1,
      price: ''
    }],
    func: []
  };

  mealModule = {
    marketing: [],
    apply: []
  }
  // 表单校验
  rules = {
    name: [{ required: true, message: '请输入套餐名称', trigger: 'blur' }],
    explain: [
      { required: true, message: '请输入套餐说明', trigger: 'blur' }
    ]
  };

  /** E Data **/

  /** S Methods **/
  handleSpecAdd() {
    if (this.form.set_meal_price.length >= 3) return this.$message.error('最多添加3条')
    this.form.set_meal_price.push({
      time: '',
      time_type: 1,
      price: ''
    })
  }

  handleSPecDel(index: number) {
    if (this.form.set_meal_price.length <= 1) return this.$message.error('最后一条不允许删除')
    this.form.set_meal_price.splice(index, 1)
  }

  mealTimeTypeChange(value: number, index: number) {
    if (value === 3) {
      this.form.set_meal_price[index].time = ''
    }
  }
  isActiveModule(name: string) {
    return this.form.func.includes(name)
  }
  // 点击表单提交
  onSubmit(formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate((valid: boolean): any => {
      if (!valid) return

      if (!this.identity) {
        this.handleSetMealAdd()
      } else {
        this.handleSetMealEdit()
      }
    })
  }

  // 添加商城
  async handleSetMealAdd(): Promise<void> {
    await apiSetMealAdd({ ...this.form })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 编辑商城
  async handleSetMealEdit(): Promise<void> {
    await apiSetMealEdit({ ...this.form, id: this.identity })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 获取详情
  async getSetMealDetailFunc(): Promise<void> {
    const res: any = await apiSetMealDetail({
      id: this.identity as number
    })
    Object.keys(res).map((key) => {
      if (res[key] === null || res[key] === undefined) return
      this.$set(this.form, key, res[key])
    })
    console.log(this.form)
  }
  getNewModuleItem(item: any) {
    if(item.is_required && !this.isActiveModule(item.en_name)) {
      this.form.func.push(item.en_name)
    }
    return {
      ...item,
      image: config.baseURL + item.image
    }
  }
  async getMealModule() {
    const res = await apiMealModule()
    this.mealModule.apply = res.apply.map(this.getNewModuleItem)
    this.mealModule.marketing = res.marketing.map(this.getNewModuleItem)
  }
  selectModule(menu: any) {
    if(menu.is_required) return
    const index = this.form.func.findIndex((item: string) => item == menu.en_name)
    if (index != -1) {
      this.form.func.splice(index, 1)
      return
    }
    this.form.func.push(menu.en_name)
  }
  /** E Methods **/

  /** S Life Cycle **/
  async created() {
    const query: any = this.$route.query

    // 编辑模式：初始化数据
    this.identity = +query.id
    if (+query.id) {
     await this.getSetMealDetailFunc()
    }
    this.getMealModule()
    this.onSubmit = throttle(this.onSubmit, 1000)
  }
  /** E Life Cycle **/
}
